import React from "react";
import { Link } from "gatsby";
import CatArticleCard from "../CatArticle-card";

function RecetasWhisky({ articles }) {
  return (
    <>
      <div className="m-4 pb-2 text-center uppercase">
        <h5>checa las</h5>
        <h1 className="text-deliciousapple">recetas</h1>
      </div>
      <div className="grid grid-cols-1 gap-6 text-deliciousapple md:grid-cols-2 lg:grid-cols-3">
        {articles.slice(0, 3).map((article) => (
          <CatArticleCard article={article} />
        ))}
      </div>
      <Link to="/category/bebidas-con-whisky" className="flex justify-end">
        <button className="my-5 uppercase text-deliciousapple">
          ver más 〉
        </button>
      </Link>
    </>
  );
}

export default RecetasWhisky;
