import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Minibanner1 from "../components/MinibannerBeb1";
import Minibanner2 from "../components/MinibannerBeb2";
import Carruselsabores from "../components/CarruselSabores";
import CarruselCompra from "../components/CarruselCompra";
import RecetasTequila from "../components/Recetas/RecetasTequila";
import RecetasVodka from "../components/Recetas/RecetasVodka";
import RecetasRon from "../components/Recetas/RecetasRon";
import RecetasWhisky from "../components/Recetas/RecetasWhisky";
import Seo from "../components/Seo";

function bebidaspreparadas() {
  const { strapiCategory } = useStaticQuery(graphql`
    query CatArticles($name: String) {
      strapiCategory(name: { eq: $name }) {
        articles {
          id
          slug
          title
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
        name
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Bebidas Preparadas con Boone´s",
          metaDescription:
            "Recetas de Cocteles y Bebidas Preparadas con Boone´s",
        }}
      />
      <header className="">
        <StaticImage
          src="../assets/images/sabores/bebidaspreparadas/banner-bebidas-preparadas.png"
          alt="Bebidas Preparadas Boones"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      <div className="container relative -mt-1 mb-20 md:-mt-3">
        <div className="z-10 flex flex-wrap justify-center gap-4">
          <div>
            <Minibanner1 />
          </div>
          <div>
            <Minibanner2 />
          </div>
        </div>
      </div>
      <main className="container">
        {/*TEQUILA*/}
        <div className="my-20 flex flex-col justify-center gap-3 md:flex-row">
          <div className="h-64 w-full max-w-lg rounded-xl drop-shadow-xl">
            <StaticImage
              src="../assets/images/sabores/bebidaspreparadas/tequila-img.png"
              alt="bebidas preparadas con tequila"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="grid items-center justify-center px-5 text-center">
            <div>
              <h5 className="uppercase">bebidas con</h5>
              <h2 className="uppercase text-exoticberry">tequila</h2>
            </div>
            <p className="max-w-prose font-light">
              El tequila es la bebida de los mexicanos por excelencia, el cual
              es considerado como parte de la identidad nacional y un
              distintivo, un símbolo a nivel mundial. Con los diferentes sabores
              de Boone’s agrega ese Twist especial que hará que todos se
              pregunten cómo es que nunca lo habían pensado antes, con nuestra
              gran variedad y tu imaginación no tendrás límites para hacer las
              más increíbles y refrescantes bebidas con tequila.
            </p>
          </div>
        </div>
        <div className="mt-4">
          <RecetasTequila
            name="Bebidas con Tequila"
            articles={strapiCategory.articles}
          />
        </div>
        {/*VODKA*/}
        <div className="mb-20 mt-10 flex flex-col justify-center gap-3 md:flex-row">
          <div className="grid items-center justify-center px-5 text-center">
            <div>
              <h5 className="uppercase">bebidas con</h5>
              <h2 className="uppercase text-pinklemonade">vodka</h2>
            </div>
            <p className="max-w-prose font-light">
              El Vodka es un ingrediente clave en una gran variedad de bebidas
              para cualquier tipo de fiesta, ya que es la mejor opción para
              combinar con lo que tú quieras. El Vodka va con todo y deja de
              lado la gran cantidad de azúcar de los jugos de fruta; descubre
              cómo al preparar bebidas con Vodka + Boone’s puedes darle ese
              Twist fresco, frutal y delicioso que a todos nos encanta.
            </p>
          </div>
          <div className="h-64 w-full max-w-lg rounded-xl drop-shadow-xl">
            <StaticImage
              src="../assets/images/sabores/bebidaspreparadas/vodka-img.png"
              alt="bebidas preparadas con vodka"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
        </div>
        <div className="mt-4">
          <RecetasVodka
            name="Bebidas con Vodka"
            articles={strapiCategory.articles}
          />
        </div>
        {/*RON*/}
        <div className="my-20 flex flex-col justify-center gap-3 md:flex-row">
          <div className="h-64 w-full max-w-lg rounded-xl drop-shadow-xl">
            <StaticImage
              src="../assets/images/sabores/bebidaspreparadas/ron-img.png"
              alt="bebidas preparadas con ron"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="grid items-center justify-center px-5 text-center">
            <div>
              <h5 className="uppercase">bebidas con</h5>
              <h2 className="uppercase text-sunpeakpeach">ron</h2>
            </div>
            <p className="max-w-prose font-light">
              El ron está hecho principalmente de caña de azúcar o de melaza y
              se produce en las zonas del caribe. Este produce un sabor dulce,
              el cual es perfecto para mezclarse con la gran variedad de sabores
              Boone’s, atrévete a realizar exquisitas bebidas con ron y llena
              tus sentidos con un nuevo Twist que consentirá a tu paladar y te
              dejará un dulce sabor de boca.
            </p>
          </div>
        </div>
        <div className="mt-4">
          <RecetasRon
            name="Bebidas con Ron"
            articles={strapiCategory.articles}
          />
        </div>
        {/*WHISKY*/}
        <div className="mb-20 mt-10 flex flex-col justify-center gap-3 md:flex-row">
          <div className="grid items-center justify-center px-5 text-center">
            <div>
              <h5 className="uppercase">bebidas con</h5>
              <h2 className="uppercase text-pinklemonade">whisky</h2>
            </div>
            <p className="max-w-prose font-light">
              El sabor amaderado nunca se llevó tan bien como con Boone’s.
              Gracias a los distintos elementos que determinan el sabor del
              whisky, como la madurez de la cebada, el suelo donde ésta fue
              plantada, el clima y la temperatura del ambiente, hacen de su
              sabor único y especial. Si quieres que tus bebidas tengan un
              sentido diferente, las bebidas con whisky se lo proporcionarán.
              ¡Descubre la nueva sensación de estas divertidas e inusuales
              bebidas con whisky!
            </p>
          </div>
          <div className="h-64 w-full max-w-lg rounded-xl drop-shadow-xl">
            <StaticImage
              src="../assets/images/sabores/bebidaspreparadas/whisky-img.png"
              alt="bebidas preparadas con whisky"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
        </div>
        <div className="mt-4">
          <RecetasWhisky
            name="Bebidas con Whisky"
            articles={strapiCategory.articles}
          />
        </div>
        {/*CARRUSELES Y SABORES*/}
        <div className="mt-10 mb-20 flex-wrap justify-center">
          <Carruselsabores />
        </div>
        <div className="mt-6 mb-20">
          <CarruselCompra />
        </div>
      </main>
    </Layout>
  );
}

export default bebidaspreparadas;
