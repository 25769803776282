import React from "react";

function MinibannerSab1() {
  return (
    <div className="w-full max-w-lg rounded-xl bg-white py-5 px-2 drop-shadow-xl md:h-64 md:py-10 md:px-7">
      <h1 className="text-2xl uppercase">Bebidas Preparadas</h1>
      <h5 className="mb-5 text-xl font-light capitalize text-pinklemonade">
        Imposible Equivocarse
      </h5>
      <p className="max-w-prose text-sm font-light">
        ¿Plan a la vista? Las bebidas preparadas con Boone’s son la solución
        perfecta para cuando estás planeando organizar la mejor fiesta, no
        importa si es para tus amig@s, tu familia o hasta con tu pareja. Boone’s
        es un vino con notas de frutas, ideal para este tipo de momentos por su
        sabor a frutas y, al ser una bebida que se disfruta mejor fría, será
        excelente para tus reuniones.
      </p>
    </div>
  );
}

export default MinibannerSab1;
